<template>
    <div data-app>
        <v-card :loading="loadingTree">
            <v-card-title>
              <h3 style="color: #e33354" class="ml-3">{{ cardTitle }}</h3>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <ProductCategoryModalForm
                            :modalData="modalData"
                            :permissions="permissions"
                            :statuses="statuses"
                            :endPoint="endPoint"
                            :productCategoryCollection="productCategoryCollection"
                            :propertyCollection="propertyCollection"
                            @closeModalForm="handleCloseModalForm"
                            @saveModalForm="handleSaveModalForm"
                            @new="handleNew"
                        ></ProductCategoryModalForm>  

                        <v-treeview
                          open-all
                          :items="computedProductCategoryCollection"
                          item-key="id"
                          dense
                          item-children="children"
                          hoverable
                        >
                          <template v-slot:prepend="{ item, open }">
                            <v-icon
                              v-if="item.translated"
                              color="green"
                              small
                              >check_circle</v-icon
                            >
                          </template>

                          <template slot="label" slot-scope="{ item }">
                            <span @click="handleClickItem(item)" style="cursor: pointer">
                              {{ $helpers.getTranslated(item.translations).name }}
                            </span>
                          </template>

                          <template v-slot:append="{ item }">
                            <!-- <v-icon
                              color="primary"
                              small
                              class="mr-2"
                              @click="handleClickItem(item)"
                            >
                              mdi-pencil
                            </v-icon> -->
                            <v-icon
                              color="primary"
                              v-if="permissionCan('delete') && item.deletable"
                              small
                              @click="deleteItem(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-treeview>

                        <DeleteModalDialog
                            :dialogDelete="dialogDelete"
                            @closeDelete="handleCloseDelete"
                            @deleteItemConfirm="handleDeleteItemConfirm"
                        >
                        </DeleteModalDialog> 
                    </v-col>  
                </v-row>
            </v-card-text>
        </v-card>  
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SiteService from "@/core/services/site.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import ProductCategoryModalForm, {
  initialFormData,
} from "./ProductCategoryModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

export const PERMISSION_TO = "products.category.";

export default {
  name: "ProductCategories",
  components: { ProductCategoryModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("MENU.CATEGORIES"),
      search: "",
      routePath: "/products/productCategories/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: this.$t("TABLE.translated"),
          value: "translated",
          width: "100px",
        },
        {
          text: this.$t("TABLE.name"),
          value: "name",
        },
        // { text: "Status", value: "status" },
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters(["productCategoryCollection", "statuses", "propertyCollection"]),
    computedProductCategoryCollection() {
        return this.createDataForTreeselect(this.productCategoryCollection);
    },
    endPoint() {
      return "products/admin/" + SiteService.getActiveSiteId() + "/category/";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchProductCategory", "fetchProperty"]),
    fetchModel() {
      return this.fetchProductCategory();
    },
    createDataForTreeselect(layer, product_category_id = null) {
      const vertex = new Map(),
        others = [];
      layer.forEach((item) => {
        if (item.product_category_id === product_category_id) {
          vertex.set(item.id, item);
        } else {
          others.push(item);
        }
      });
      for (const vertexId of vertex.keys()) {
        const children = this.createDataForTreeselect(others, vertexId);
        if (children.length) {
          vertex.get(vertexId).children = children;
        }
      }
      return [...vertex.values()];
    },
  },

  mounted() {
    this.loadingTree = true;
    this.fetchModel().then(() => (this.loadingTree = false));
    this.fetchProperty();
  },
};
</script>

